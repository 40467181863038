/// @group Card
.card {
    box-shadow: $card-box-shadow;
    border: none;
    position: relative;
    overflow: hidden;

    @extend %mobile-container;

    bdi {
        font-weight: 500;
    }

    &-img-cap {
        background-color: $gray-300;
        display: flex;
        position: relative;
        justify-content: center;
        &.card-img-cap--cover {
            min-height: 250px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        &.product-image-wrapper{
            background-color: white;
            padding-top: 30px;

            picture{
                max-width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                @include media-breakpoint-up(md){
                    max-width: 90%;
                }
                img{
                    position: relative;
                    max-width: 100%;
                    height: auto;
                    @include media-breakpoint-up(md){
                        height: 291px;
                    }
                }
            }
        }
    }

    &-tags {
        position: absolute;
        top: 1rem;
        left: 1.5rem;
    }

    &-title {
        font-size: rem-val(24);
        color: $body-color !important;
    }

    &-subtitle {
        font-size: rem-val(14);
        color: $gray-600;
        text-transform: uppercase;
        text-decoration: none;
        a {
            color: inherit;
            &:not(:hover) {
                text-decoration: inherit;
            }
        }
        margin-bottom: 1.5rem;
    }

    &-text {
        font-size: rem-val(18);
        color: $body-color;
    }

    &-link {
        font-weight: 500;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        &:after {
            content: '';
            display: inline-block;
            margin-left: .5rem;
            width: .8em;
            height: .8em;
            background-image: escape-svg( url( "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='#{$primary}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>" ) );
        }
    }

    .card-body{
        @media (max-width: 768px) {
            padding: 15px;
        }
    }

    // @variants
    &.is-style-overlap {
        margin-top: -3rem;
        margin-bottom: 3rem;
        z-index:2;
    }

    &--video {
        width: 100%;

        border: none;
        &:hover {
            outline: 5px solid rgba($green, .2);
        }

        > a {
            width: 100%;
            text-decoration: none;
            p {
                margin-bottom: .5rem;
            }
        }

        .card-bg-img {
            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($black, .2);
            }
        }

        .card-body {
            position: relative;
            min-height: 360px;
            display: flex;
            align-items: flex-end;

            padding: 2rem !important;

            > * {
                max-width: 100% !important;
                width: 100%;
            }

            &__content {
                position: relative;
                padding-left: 56px;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 8px;
                    display: block;
                    width: 42px;
                    height: 42px;
                    background-repeat: no-repeat;
                    background-image: escape-svg( url( "data:image/svg+xml,<svg width='42px' height='42px' viewBox='0 0 42 42' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><circle fill-opacity='0.59060861' fill='#FFFFFF' cx='21' cy='21' r='21'></circle><g transform='translate(11.000000, 11.000000)'><path d='M10.5,0 C4.70101013,0 0,4.70101013 0,10.5 C0,16.2989899 4.70101013,21 10.5,21 C16.2989899,21 21,16.2989899 21,10.5 C21,7.71522686 19.8937532,5.04451077 17.9246212,3.0753788 C15.9554892,1.10624682 13.2847731,0 10.5,0 Z' id='Fill' fill='#80C41C' transform='translate(10.500000, 10.500000) scale(-1, 1) rotate(90.000000) translate(-10.500000, -10.500000) '></path><polygon stroke='#FFFFFF' fill='#FFFFFF' stroke-linecap='round' stroke-linejoin='round' points='8.35819803 15.1387262 14.0713131 10.6387262 8.35819803 6.13872619'></polygon></g></svg>" ) );
                }
            }
        }
    }

    &--has-image {
        border: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        .card-img-top {
            img {
                width: 100%;
            }
        }
    }

    &--has-background-image {
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        border: none;

        .card-body {
            padding-top: 3rem;
            padding-bottom: 3rem;
            * {
                color: var(--white, #fff) !important;
                @include media-breakpoint-up(lg) {
                    max-width: rem-val(920);
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            @include media-breakpoint-down(md) {
                padding: 1.25rem;
            }
        }

        > * {
            position: relative;
        }

        .card-bg-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }

    &.is-style-earmark {
        position: relative;
        border: none;
        overflow: hidden;
        .card-body {
            padding: 4rem 2rem 2rem 6rem;
        }
        @include media-breakpoint-up(md) {
            .card-body {
                padding: 80px 100px 32px 150px;
            }
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 155px;
            height: 163px;
            background-image: escape-svg( url( "data:image/svg+xml,#{$corner-leaves-svg}" ) );
            background-size: contain;
            background-repeat: no-repeat;
        }

        .wp-block-column & {
            height: 100%;
            align-self: stretch;

            .card-body {
                display: flex;
                flex-direction: column;

                > * {
                    margin-bottom: 2.5rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                p {
                    line-height: 1.3;
                }

                .wp-block-buttons {
                    margin-top: auto;
                    .wp-block-button {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.wp-block-cover{
    &.is-style-image-card-right,
    &.is-style-image-card-center{
        @include media-breakpoint-down(md) {
        .wp-block-cover__inner-container {
                position: absolute;
                bottom: -18%;
            }
        }
    
        @include media-breakpoint-down(sm) {
            margin-bottom: 30%;
            display: block;
    
            img{
                display: block;
                position: relative;
            }
            .wp-block-cover__inner-container {
                // position: absolute;
                // bottom: -30%;
                position: relative;
                display: block;
                margin: -90px auto 0;
                max-width: 90% !important;
                bottom:unset;
    
            }
        }
    }
}