/// @group Loop
// Used anywhere there are multiple posts 
// displayed in a single iterator (shop, blog, etc.)
.loop {
    --rwp-gutter-x: 3rem;
    --rwp-gutter-y: 3rem;
    @include media-breakpoint-down(md) {
        &.row {
            margin-top: 0;
        }
    }
    &-item {
        // margin-bottom: $spacer * 3;
    }
    &--testimonials {
        margin-bottom: calc( var(--rwp-gutter-y) * 3 );
    }
    &-testimonials-wrapper {
        margin: 5rem 0;
        .loop {
            margin-bottom: 0;
        }
    }
}