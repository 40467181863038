/// @group Gutenberg_Button
.wp-block {
    &-button {
        border-radius: $border-radius;
        &__link {
            @extend .btn;
            @include button-variant($primary, $primary);
            border-width: $btn-border-width !important;
            box-shadow: none;
            &.no-border-radius {
                border-radius: inherit !important;
            }
            @each $color, $hex in map-merge($gutenberg-colors, ( 'white': #fff, 'black': #000)) {
                &.has-#{$color}-color {
                    color: $hex;
                }
                &.has-#{$color}-background-color {
                    @include button-variant($hex, $hex);
                    &:hover {
                        background-color: darken($hex, 5%);
                        border-color: darken($hex, 5%);
                    }
                }
            }
        }
        &.is-style-outline {
            .wp-block-button__link {
                padding: 0.80556rem 1.55556rem;
                background-color: transparent;
                // @TODO figure out hover styling
                @extend .btn-outline-primary;
                color: $primary;
                border-color: $primary;
                &:hover {
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                }
                @each $color, $hex in map-merge($gutenberg-colors, ( 'white': #fff, 'black': #000)) {
                    &.has-#{$color}-color {
                        border-color: $hex;
                        color: $hex;
                        &:hover {
                            color: color-contrast($hex);
                            background-color: $hex;
                        }
                    }
                }
                &.has-white-background-color{
                    background-color: white;
                    &:hover{
                        background-color: transparent;
                        color: white;
                        border-color: currentColor;
                    }
                }
            }
        }

        // Video Toggle
        &[data-iframe-src] {
            .wp-block-button__link {
                @include bootstrap-icon-psuedo( 'play-circle', $white, 'before' );
                &:before {
                    transition: all .3s;
                    width: 1.5em !important;
                    height: 1.5em !important;
                }
                @each $color, $hex in map-merge($gutenberg-colors, ( 'white': #fff, 'black': #000)) {
                    &.has-#{$color}-color {
                        @include bootstrap-icon-psuedo( 'play-circle', $hex, 'before' );
                        &:hover {
                            $contrast-hex: color-contrast($hex);
                            @include bootstrap-icon-psuedo( 'play-circle', $contrast-hex, 'before' );
                        }
                    }
                }
            }
        }
    }
    &-buttons {
        @include media-breakpoint-down(md) {
            max-width: calc( 100vw - ( #{$spacer} * 2 ) );
            &.is-content-justification-center {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.is-style-with-icons,
        &.is-style-bold-with-icons {
            width: 100%;
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                //justify-content: center;
            }

            .wp-block-button {
                .wp-block-button__link {
                    padding: 0 !important;
                    background-color: transparent !important;
                    border: none !important;
                    color: $primary;
                    text-decoration: underline;
                    font-size: 1rem !important;
                    font-weight: normal;
                    @include bootstrap-icon-psuedo( 'chevron-right', $primary, 'after' );
                    &:hover {
                        color: $link-hover-color;
                    }
                }
            }
        }
        &.is-style-bold-with-icons {
            .wp-block-button {
                &__link {
                    text-decoration: none !important;
                    font-weight: bold !important;
                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }
}