/// @group Button
.btn-link {
    text-decoration: none;
    font-weight: $btn-font-weight;
}
.btn-addon {
    // used with input-group
    border: 1px solid $input-border-color;
    border-left: none;
    border-top-right-radius: $input-border-radius-sm;
    border-bottom-right-radius: $input-border-radius-sm;

    padding-left: .75rem !important;
    padding-right: .75rem !important;

    @include on-action {
        background-color: $gray-300;
    }
}

.woocommerce a.button {
    @extend .btn;
    @extend .btn-primary;
}