/// @group Modal
.modal {
    .btn-close {
        position: fixed;
        top: .5rem;
        right: .5rem;

        width: 1.5rem;
        height: 1.5rem;

        padding: 0;

        background: transparent escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") );
    }
    &-backdrop {
        &.show {
            opacity: .75;
        }
    }
    &--video {
        .modal-dialog {
            max-width: none;
            width: 100vw;
            height: 100vh;
            padding: 3rem;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                padding: 1rem;
            }
        }

        .modal-content {
            width: 100%;
            height: 100%;

            @include media-breakpoint-up(lg) {
                max-width: 80vw;
                margin: 0 auto;
            }

            display: flex;
            justify-content: center;

            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }
}