blockquote {

    &.wp-block-quote {
        padding-left: 0;
        border-left: none;
    }

    p {
        font-weight: bold;
        .wp-block-eedee-block-gutenslider & {
            font-weight: normal;
        }
        font-size: 24px !important;
        line-height: 1.1 !important;
        margin-bottom: 2.5rem !important;

        @include media-breakpoint-down(md) {
            font-size: 16px !important;
        }
    }

    cite {
        display: block;
        font-size: 16px;
        line-height: 1.2;
        strong {
            font-size: 18px;
        }
    }
}