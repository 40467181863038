.nav {
  &-item {
  }

  &-link {
    &--checkbox {
      color: $body-color;
      @include bootstrap-icon-psuedo('square', $gray-300, 'before');

      &:before {
        transition: all .1s;
      }

      &.active {
        @include bootstrap-icon-psuedo('check-square', $green, 'before');
      }

      @include on-action {
        color: $body-color;
        &:not(.active) {
          @include bootstrap-icon-psuedo('square', $green, 'before');
        }
      }
    }
  }

  // Category checkboxes
  &--post-categories {
    margin-bottom: 2rem;
  }

  // Loop
  &--loop {
    @extend .nav;
    font-size: rem-val(16);

    .nav-item {
      & + .nav-item {
        margin-left: 2rem;
      }

      .nav-link {
        font-weight: 500 !important;
        border: 1px solid $primary;

        &:not(.active) {
          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }

    margin-bottom: 3.5rem;
  }

  // Social
  &--social-share {
    .nav-item {
      + .nav-item {
        margin-left: 1rem;
      }

      .nav-link {
        padding: 0;
        font-size: 2rem;
        @each $network in ('facebook', 'twitter', 'pinterest', 'instagram') {
          &.has-icon-#{$network} {
            @include bootstrap-icon-psuedo($network, $primary, 'after');
          }
        }

        &:after {
          margin-left: 0 !important;
        }

        &:hover {
          opacity: .85;
        }
      }
    }
  }

  &-pills {
    @include media-breakpoint-down(sm) {

      .nav-item {
        margin: .25rem .5rem !important;
      }
    }
  }
}