.wp-block-media-text {
    h1, h2, h3, h4, h5, h6 {
        line-height: 1;
        margin-bottom: 2rem;
    }
    &__media {
        overflow: hidden;
        img {
            &.size-full {
                width: 100%;
                height: auto;
            }
        }
    }
    &__content {
        .is-style-hero-split & {
            align-self: stretch !important;
            display: flex;
            flex-direction: column;
            justify-content: center;

            background-image: url(/assets/images/blue_hero_leaves_half@2x.jpg);
            background-size: cover;
            background-repeat: no-repeat;

            h1 {
                font-size: rem-val(48);
            }
            
            padding: 4rem 0;
            > * {
                @extend .contained-start;
                margin-bottom: $spacer * 2;
                @include media-breakpoint-up(lg) {
                    padding-right: $spacer * 8;
                }

                color: $white !important;
            }
        }

        @include media-breakpoint-down(md) {
            padding-left: $spacer !important;
            padding-right: $spacer !important;
        }
    }

    &.is-style-hero-split {
        // min-height: 762px;
        .wp-block-media-text__media {
            align-self: stretch;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &.is-style-featured {
        background-color: $gray-100;
        
        margin-bottom: 4rem;
        @include media-breakpoint-up(lg) {
            min-height: 600px;
            margin-bottom: 8rem;
        }
        .wp-block-media-text__media {
            align-self: stretch;
            position: relative;
            img {
                position: absolute;
                min-height: 100%;
                min-width: 100%;
                object-fit: cover;
            }
            @include media-breakpoint-down(md) {
                img {
                    position: relative;
                    min-width: 100%;
                    min-height: 0px;
                    height: auto;
                    width: 100%;
                }
            }
        }
        .wp-block-media-text__content {
            padding-top: 3rem;
            padding-bottom: 3rem;
            @extend .contained-end;
        }
        .has-medium-gray-color {
            text-transform: uppercase;
        }
        a:not(:hover) {
            text-decoration: none;
        }
        .is-style-chevron-icon {
            font-weight: 500;
        }
    }

    &.is-style-with-card {
        $border-radius: 20px !important;
        overflow: hidden;
        padding-top: 5rem;
        padding-bottom: 5rem;
        .wp-block-media-text__media {
            border-radius: $border-radius;
            align-self: stretch;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            @include media-breakpoint-up(md) {
                //transform: translateX(1.5rem);
            }
        }
        .wp-block-media-text__content {
            border-radius: $border-radius;
            @extend .card;
            @extend .card-body;

            margin-top: 2rem;

            @include media-breakpoint-up(md) {
                margin-top: 4rem;
                margin-bottom: 4rem;
                padding: 5rem 4rem;
                //transform: translateX(-1.5rem);
            }

            > :first-child {
                &:before {
                    // Leaf
                    content: '';
                    width: 40px;
                    height: 64.53px;
                    position: relative;
                    display: block;
                    $leaf-svg: "<svg width='40px' height='64.53px' viewBox='0 0 87 140' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><polygon id='path-1' points='0 0 87 0 87 140 0 140'></polygon></defs><g stroke='none' stroke-width='1'><mask id='mask-2' fill='white'><use xlink:href='#path-1'></use></mask><path d='M7.44474965,44.1584129 C25.7416373,5.49255679 76.6366195,0 76.6366195,0 C76.6366195,0 97.8667946,57.2099816 79.5553394,95.8571556 C61.2459652,134.539618 10.3499425,140 10.3499425,140 C10.3499425,140 -10.8594218,82.8076627 7.44474965,44.1584129' fill='#80C41C' mask='url(#mask-2)'></path><path d='M17.4633986,122.286504 C17.571615,122.265746 17.4883716,122.045712 17.598669,122.009386 L67.6820287,17.4878566 C67.6237583,17.3342477 67.8557992,17.3757636 67.8068937,17.2231926 C56.5118121,20.16252 27.3756001,28.0650762 16.8442762,52.4826671 C6.3379253,76.8836516 13.2013392,108.917339 17.4633986,122.286504' fill='#FFFFFF' mask='url(#mask-2)'></path><polygon fill='#FFFFFF' mask='url(#mask-2)' points='29.6434611 117.442426 36.9491053 102.169756 57.5289417 104.798752'></polygon><polygon fill='#FFFFFF' mask='url(#mask-2)' points='42.9820628 89.5553496 50.287707 74.282679 69.9060828 76.2256242'></polygon><polygon fill='#FFFFFF' mask='url(#mask-2)' points='56.3531294 61.6563371 63.6618953 46.4480161 82.3073644 47.6561295'></polygon><polygon fill='#80C41C' mask='url(#mask-2)' points='29.3916499 77.2062304 36.6993752 61.981303 25.8101049 41.8460797'></polygon><polygon fill='#80C41C' mask='url(#mask-2)' points='18.6711555 99.6380108 25.9653538 84.3944013 15.0896105 64.2612538'></polygon><polygon fill='#80C41C' mask='url(#mask-2)' points='41.7906424 51.3628788 49.093165 36.122383 38.2111785 15.9881976'></polygon></g></svg>";
                    background-image: escape-svg( url( "data:image/svg+xml,#{$leaf-svg}" ) );
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-bottom: 2rem;
                }
            }
        }

        &.has-media-on-the-right {
            .wp-block-media-text__media {
                //transform: translateX(-1.5rem);
            }
            .wp-block-media-text__content {
                //transform: translateX(1.5rem);
            }
        }
    }

    &:not([class*=is-style]) {
        padding-top: 5rem;
        padding-bottom: 5rem;

        @include media-breakpoint-down(sm) {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    &--iframe {
        .wp-block-media-text__media {
            position: relative;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &.is-stacked-on-mobile {
        .wp-block-media-text__content {
        
            @include media-breakpoint-down(md) {
                margin-top: 3rem;
            }
        }
    }
}