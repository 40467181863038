/// @group Cover
.wp-block-cover {
    @include media-breakpoint-up(lg) {
        min-height: rem-val(500);
    }
    &__image-background {}
    &__inner-container {
        > * {
            margin-bottom: 1.5rem;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 2rem $spacer;
        }
    }
    &.is-style-hero {
        @include media-breakpoint-up(lg) {
            min-height: rem-val(700) !important;
        }
        & + .card.is-style-overlap {
            margin-top: -5rem;
        }
        &-short {
            @include media-breakpoint-up(lg) {
                min-height: rem-val(300) !important;
            }
        }
    }
    &.is-style-hero-offset {
        background-color: $black;
        .wp-block-cover__inner-container {
            @extend .container;
            color: $white;
        }
        .wp-block-cover__image-background {
            // z-index: 2;
        }

        @include media-breakpoint-up(lg) {
            .wp-block-cover__background,
            .wp-block-cover__gradient-background {
                background-color: transparent !important;
                opacity: 1;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 50% !important;
                    opacity: 1 !important;
                    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 75%, rgba(0,0,0,1) 100%);
                }
                @each $color, $hex in $gutenberg-colors {
                    &.has-#{$color}-background-color {
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 50%;
                            bottom: 0;
                            left: 0;
                            background-color: $hex;
                        }
                        &:before {
                            background: linear-gradient(90deg, rgba($hex,1) 0%, rgba($hex,0) 50%, rgba($hex,0) 75%, rgba($hex,1) 100%);
                        }
                    }
                }
            }
            .wp-block-cover__image-background {
                left: 50%;
                width: 50%;
            }
            .wp-block-cover__inner-container {
                > * {
                    max-width: 50%;
                }
            }
        }
    }

    // Cards
    &.is-style-image-card-right,
    &.is-style-image-card-center,
    &.is-style-image-card-left {
        .wp-block-cover__background {
            &:before {
                display: none;
            }
        }
        // Leaf
        &:after {
            content: '';
            width: 87px;
            height: 140px;
            position: absolute;
            display: block;
            background-image: escape-svg( url( "data:image/svg+xml,#{$leaf-svg}" ) );
            background-repeat: no-repeat;
            background-size: contain;
            right: 4rem;
            top: -4rem;

            z-index: 2;

            @include media-breakpoint-down(md) {
                right: 1rem;
            }
        }
        
        background-color: transparent !important;
        [class^="wp-block-cover"] {
            border-radius: 20px !important;
        }

        min-height: 500px;
        @include media-breakpoint-up(lg) {
            min-height: 780px;
        }
        align-items: flex-end;
        padding: 0;
        margin: 6rem auto 9rem;
        
        .wp-block-cover__image-background {
        }
        .wp-block-cover__inner-container {
            @extend .card, .card-body;

            color: $body-color;
            font-size: 20px;
            line-height: 1.5;
            :last-child {
                margin-bottom: 0 !important;
            }

            @include headings {
                line-height: 1.1;
                $spacer: 2.5rem;
                &:first-child {
                    margin-top: $spacer;
                }
                &:last-child {
                    margin-bottom: $spacer !important;
                }
            }

            padding: 2rem 4rem !important;

            .wp-block-button {
                font-size: 14px;
            }

            [data-rich-text-placeholder] {
                color: $black;
            }

            max-width: 740px;
        }

        @extend %mobile-container;
        @include media-breakpoint-down(md) {
            justify-content: center !important;
            .wp-block-cover__inner-container {
                padding: 2rem !important;
                transform: translateY(2rem) !important;
                max-width: calc(100% - (#{$spacer} * 2)) !important;
            }
        }
    }

    &.is-style-image-card-right,
    &.is-style-image-card-left {
        .wp-block-cover__inner-container {
            max-width: 510px;
        }
    }

    &.is-style-image-card-center {
        .wp-block-cover__inner-container {
            transform: translateY(3rem);
        }
    }
    &.is-style-image-card-right {
        justify-content: flex-end;
        .wp-block-cover__inner-container {
            transform: translate(-4rem, 4rem);
        }
    }
    &.is-style-image-card-left {
        justify-content: flex-start;
        .wp-block-cover__inner-container {
            transform: translate(4rem, 4rem);
        }
    }

    // Testimonials
    &.is-style-testimonials {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}