/// @group Collapse

.collapse {
    font-size: inherit;

    // padding-left: 2.1rem;
    // padding-top: 0;

    display: none;
    &:focus-within,
    &.show,
    .has-child-selected > & {
        display: block !important;
    }

    &-wrapper {
        // card styles
        background-color: $white;
        box-shadow: $box-shadow;
        border-color: $white;
        border-radius: $card-border-radius;
        color: $body-color;
        font-size: 20px;
        line-height: 1.6;
        padding: 1.5rem 2.5rem !important;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    &-toggle-wrapper {
        
    }

    &-toggle {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        
        // Unset button/a styles
        padding: 0;

        text-decoration: none !important;
        border: none !important;
        background-color: transparent !important;

        font-size: 24px;
        line-height: 39px;
        font-weight: bold;

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }

        &__text {}
        &__icon {
            @include bootstrap-icon-psuedo( 'chevron-down', $body-color, 'after' );
            .collapse-toggle[aria-expanded="true"] & {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &-content {
        margin-top: 1.5rem;
        padding: 0;

        .logo{
            width: 200px; 
            height: 200px; 
            background-position: center; 
            background-size: cover; 

            @include media-breakpoint-down(md) {
                margin: 0 auto 1rem auto;
            }
        }
        .contact{
            @include media-breakpoint-down(md) {
                // text-align: center;
            }
        }
    }
}