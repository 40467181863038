.single {

    .post-content {
        .container { 
            max-width: 960px;
        }

        &-section {
            padding: 2rem 0;
            @include media-breakpoint-up(md) {
                padding: 4rem 0;
            }
            & + .post-content-section {
                border-top: 1px solid $gray-200;
            }

            &--content {
                img {
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: auto;
                    }
                }
                > * + * {
                    margin-top: 4rem;
                }
            }
        }
    }
}