/// @group Alert

// Woocommerce Store Notice inherits alert styling
.woocommerce-store-notice {
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;

    &__dismiss-link {
        overflow: hidden;
        display: inline-block;
        color: transparent !important;
        @extend .btn-close;
    }

    &-wrapper {
        @extend .alert;
        @extend .alert-warning;
        @extend .alert-dismissible;
        border-radius: 0;
        margin-bottom: 0;

        padding-top: 0;
        padding-bottom: 0;
        border: none;
        
        .demo_store {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        @include media-breakpoint-down(md) {
            padding-right: 3rem;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 16px;
        text-align: left;
    }
}