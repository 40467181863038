// Required svg map
@import '../bootstrap-icon-svgs';

/// @group Mixins
@mixin bootstrap-icon-psuedo( $icon, $color, $order: 'after' ) {
    position: relative;
    display: inline-flex;
    align-items: center;

    @if 'after' == $order {
        &:after {
            content: '';
            display: inline-block;
            margin-left: .5em;
            width: 1em;
            height: 1em;
            min-width: 1em;
            background-repeat: no-repeat;
            overflow: hidden;
        }
    } 
    @else {
        &:before {
            content: '';
            display: inline-block;
            margin-right: .5em;
            width: 1em;
            height: 1em;
            background-repeat: no-repeat;
            overflow: hidden;
        }
    }

    $svg: map-get( $bootstrap-icon-svgs, $icon );
    $replaced-string: str-replace( $svg, 'currentColor', $color );
    $background-image: escape-svg( url( "data:image/svg+xml,#{$replaced-string}" ) );

    &:#{$order} {
        background-image: $background-image;
    }
}