/// @group Newsletter
.newsletter-signup {
    &__content {
        p:first-child {
            margin-bottom: 0;
        }
        strong {
            font-size: 1.6rem;
            text-transform: none;
        }
        em {
            font-size: 1.2rem;
        }
        p:last-child {
            line-height: 1rem * calc( 32 / 20 );
        }
    }
    &__form {}
}