section{

    .section-column{
        // margin-bottom: 0.5rem;
        // margin-top: 0.5rem;

        &>div{
            height: 100%;
        }
    }

    
}

div[data-inviewport]{
    visibility: hidden;
}