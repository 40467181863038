@mixin blur($amount: 2px) {
	filter: blur($amount);
}

@mixin border-radius(
	$top-right: 0,
	$bottom-right: 0,
	$bottom-left: 0,
	$top-left: 0
) {
	border-top-right-radius: $top-right;
	border-bottom-right-radius: $bottom-right;
	border-bottom-left-radius: $bottom-left;
	border-top-left-radius: $top-left;
	@include background-clip(padding-box);
}

@mixin columns(
	$colwidth: 250px,
	$colcount: 0,
	$colgap: 50px,
	$column-rule-color: #eee,
	$column-rule-style: solid,
	$column-rule-width: 1px
) {
	column-width: $colwidth;
	column-count: $colcount;
	column-gap: $colgap;
	column-rule-color: $column-rule-color;
	column-rule-style: $column-rule-style;
	column-rule-width: $column-rule-width;
}

@mixin drop-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: 0.1) {
	box-shadow: $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
}

@mixin flat-button(
	$bg-color: blue,
	$hover-bg-color: darken(blue, 20),
	$border-color: darken(blue, 4),
	$hover-border-color: darken(blue, 30),
	$double-border: false,
	$double-border-color: lighten(blue, 14),
	$round: 3px,
	$text-color: #fff,
	$text-hover-color: #fff,
	$padding: 6px 12px,
	$font-size: 14px
) {
	border: 1px $border-color solid;
	@include rounded($round);
	position: relative;
	color: $text-color;
	text-shadow: 0 0 12px darken($bg-color, 15), 0 0 2px darken($bg-color, 25);
	display: inline-block;
	font-weight: normal;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	white-space: nowrap;
	user-select: none;
	padding: $padding;
	font-size: $font-size;
	line-height: 1.42857143;
	@include transition(0.4s);
	background: $bg-color;
	box-shadow: none;
	text-decoration: none;

	@if $double-border {
		&:before {
			@include double-border(
				$round: $round,
				$border-color: $double-border-color
			);
		}
	}

	&:hover {
		background-color: $hover-bg-color;
		border: 1px $hover-border-color solid;
		text-decoration: none;
		color: $text-hover-color;
		text-shadow: 0 0 12px darken($hover-bg-color, 15),
			0 0 2px darken($hover-bg-color, 25);
	}

	&:link,
	&:active,
	&:visited,
	&:focus {
		text-decoration: none;
		color: $text-color;

		&:hover {
			text-decoration: none;
			color: $text-hover-color;
		}
	}
}

@mixin flexbox(
	$display: flex,
	$flex-direction: row,
	$justify-content: flex-start,
	$align-items: flex-start,
	$align-content: flex-start,
	$flex-wrap: wrap
) {
	display: $display; // flex | inline-flex
	flex-direction: $flex-direction; // row | row-reverse | column | column-reverse
	justify-content: $justify-content; // flex-start | flex-end | center | space-between | space-around
	align-items: $align-items; // flex-start | flex-end | center | stretch | baseline
	align-content: $align-content; // flex-start | flex-end | center | stretch | space-between | space-around
	flex-wrap: $flex-wrap; // nowrap | wrap | wrap-reverse
}

@mixin font-face($family, $file-base, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		src: url('${file-base}.eot');
		src: url('${file-base}.eot?#iefix') format('embedded-opentype'),
			url('${file-base}.woff') format('woff'),
			url('${file-base}.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

@mixin gradient($color: #000, $start: blue, $stop: #fff) {
	background: $color; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(
		$start,
		$stop
	); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($start, $stop); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($start, $stop); /* For Firefox 3.6 to 15 */
	background: linear-gradient($start, $stop); /* Standard syntax */
}

@mixin gradient-button(
	$start-color: blue,
	$hover-start-color: darken(blue, 20),
	$gradient-percent: 18%,
	$gradient-invert: false,
	$hover-gradient-invert: false,
	$double-border: false,
	$double-border-color: lighten(blue, 14),
	$round: 3px,
	$text-color: #fff,
	$text-hover-color: #fff,
	$padding: 6px 12px,
	$font-size: 14px
) {
	$end-color: darken($start-color, $gradient-percent);
	border: 1px darken($end-color, 4) solid;
	@include gradient($start-color, $end-color, $start-color);
	@if $gradient-invert {
		@include gradient($start-color, $start-color, $end-color);
	}
	@include rounded($round);
	position: relative;
	color: $text-color;
	text-shadow: 0 0 12px darken($end-color, 15%),
		0 0 2px darken($end-color, 25%);
	display: inline-block;
	font-weight: normal;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	white-space: nowrap;
	user-select: none;
	padding: $padding;
	font-size: $font-size;
	line-height: 1.42857143;
	@include transition(0.4s);
	text-decoration: none;

	@if $double-border {
		&:before {
			@include double-border(
				$round: $round,
				$border-color: $double-border-color
			);
		}
	}

	$hover-end-color: darken($hover-start-color, $gradient-percent);
	&:hover {
		@include gradient(
			$hover-start-color,
			$hover-start-color,
			$hover-end-color
		);
		@if $gradient-invert {
			@if $hover-gradient-invert {
				@include gradient(
					$hover-start-color,
					$hover-end-color,
					$hover-start-color
				);
			}
		} @else {
			@if $hover-gradient-invert != true {
				@include gradient(
					$hover-start-color,
					$hover-end-color,
					$hover-start-color
				);
			}
		}

		border: 1px darken($hover-end-color, 4) solid;
		text-decoration: none;
		color: $text-hover-color;
		text-shadow: 0 0 12px darken($hover-end-color, 15%),
			0 0 2px darken($hover-end-color, 25%);
		@include rounded($round);
	}

	&:link,
	&:active,
	&:visited,
	&:focus {
		text-decoration: none;
		color: $text-color;

		&:hover {
			text-decoration: none;
			color: $text-hover-color;
		}
	}
}

@mixin headings {
	h1, h2, h3, h4, h5, h6 {
		@content;
	}
}

@mixin horizontal-list($padding: 5px 10px) {
	list-style-type: none;
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		display: inline-block;
		padding: $padding;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}

@mixin inner-shadow($horizontal: 0, $vertical: 1px, $blur: 2px, $alpha: 0.4) {
	box-shadow: inset $horizontal $vertical $blur rgba(0, 0, 0, $alpha);
}

@mixin line(
	$color: blue,
	$width: 100%,
	$height: 1px,
	$alignment: center,
	$margin-top: 10px,
	$margin-bottom: 10px
) {
	display: block;
	width: $width;
	height: $height;
	background: $color;
	border: none;
	margin-top: $margin-top;
	margin-bottom: $margin-bottom;
	@if $alignment == center {
		margin-left: auto;
		margin-right: auto;
	}
	@if $alignment == left {
		margin-left: 0;
		margin-right: 0;
	}
	@if $alignment == right {
		float: right;
		margin-left: 0;
		margin-right: 0;
	}
}

@mixin opacity($opacity: 0.5) {
	opacity: $opacity;
}

@mixin overlay($bg-color: black, $opacity: 0.4) {
	position: relative;

	&:before {
		@include transition(0.4s);
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $bg-color;
		opacity: $opacity;
		z-index: 1000;
	}
}

@mixin _placeholder($color: grey) {
	&::placeholder {
		color: $color;
	}
}

@mixin rotation($deg: 5deg) {
	@include transform(rotate($deg));
}

@mixin rounded($radius: 2px) {
	border-radius: $radius;
}

@mixin scale($ratio: 1.5) {
	@include transform(scale($ratio));
}

@mixin text-shadow($bg-color: black) {
	text-shadow: 0 0 12px darken($bg-color, 15%), 0 0 2px darken($bg-color, 25%);
}

@mixin transition($duration: 0.2s, $ease: ease-out) {
	transition: all $duration $ease;
}

@mixin translate($x: 0, $y: 0) {
	@include transform(translate($x, $y));
}

@mixin triangle($direction: right, $size: 40px, $color: red) {
	width: 0;
	height: 0;

	@if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}

	@if $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	}

	@if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}

	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	}
}

@mixin absolute-full {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin border-gradient($color: black, $width: 1px) {
	border-top: $width solid $color;
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		from($color),
		to(transparent)
	);
	background-image: -webkit-linear-gradient(#000, transparent);
	background-image: -moz-linear-gradient($color, transparent),
		-moz-linear-gradient($color, transparent);
	background-image: -o-linear-gradient($color, transparent),
		-o-linear-gradient($color, transparent);
	background-image: linear-gradient($color, transparent),
		linear-gradient($color, transparent);
	-moz-background-size: $width 100%;
	background-size: $width 100%;
	background-position: 0 0, 100% 0;
	background-repeat: no-repeat;
}

@mixin center-center {
	display: table;
	height: 100%;
	width: 100%;
	> * {
		display: table-cell;
		vertical-align: middle;
	}
}

@mixin double-border($round: 3px, $border-color: lighten(grey, 10)) {
	content: ' ';
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@include rounded($round);
	@include border-gradient($border-color);
}

@mixin transform($arguments) {
	transform: $arguments;
}

@mixin box-shadow($arguments) {
	box-shadow: $arguments;
}

@mixin background-clip($argument: padding-box) {
	background-clip: $argument;
}

/*
   *
   * Regular Mixins
   * 
   */

@mixin lowercase {
	text-transform: lowercase !important;
}

@mixin uppercase {
	text-transform: uppercase !important;
}

@mixin no-top-margin {
	margin-top: 0px !important;
}

@mixin no-bottom-margin {
	margin-bottom: 0px !important;
}

@mixin bg-cover {
	background-position: center center;
	background-size: cover;
}

@mixin bg-image {
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-size: cover !important;
}

@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@mixin clearfix {
	*zoom: 1;

	&:before,
	&:after {
		display: table;
		content: '';
		line-height: 0;
	}

	&:after {
		clear: both;
	}
}

@mixin no-padding {
	padding: 0;
}

@mixin plain-list {
	list-style-type: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

@mixin same-height-columns {
	display: table;

	> [class*='col-'],
	> div {
		float: none;
		display: table-cell;
		vertical-align: top;
	}
}

@mixin visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
