// ==========================================================================
//   WordPress Generated Classes
//   See: http://codex.wordpress.org/CSS#WordPress_Generated_Classes
// ==========================================================================


//** Bootstrap 4 is flexed now. You can use the class .mx-auto with divs that have width. New support classes are here https://getbootstrap.com/docs/4.0/utilities/spacing/#notation ***
// .aligncenter { display: block; margin: 0 auto; }
// .alignleft { float: left; }
// .alignright { float: right; }
// figure.alignnone { margin-left: 0; margin-right: 0; }

/* WordPress Class Extensions */

.alignleft {
  margin-right: 35px;
  
  @include media-breakpoint-down(md){
	  margin-right: 0;
	  margin-bottom: 8px;
	  width: 100%;
	  height: auto;
  }
}

.alignright {
  margin-left: 35px;

  @include media-breakpoint-down(md){
	  margin-left: 0;
	  margin-bottom: 8px;
	  width: 100%;
	  height: auto;
  }
}

// Accomodate for Admin Bar
body {
	&.admin-bar {
		@include media-breakpoint-up(md) {
			//padding-top: 58px !important;
		}
		@include media-breakpoint-down(md) {
			//padding-top: 38px !important;
		}
	}
}


// Admin Bar Styles Ghosted
#wpadminbar {
	// transition: all .3s;
	// opacity: 0;
	// &:hover {
	// 	opacity: 1;
	// }
}

//admin
#wpAdminToggleButton {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1000;
	font-size: 10px;
	padding: 10px 15px;
	background-color: #000;
	color: #fff;
	border: none;
	border-top-left-radius: 5px;
	opacity: .75;
	&:hover {
		opacity: 1;
	}
	// display: inline-block;
	display: none;
}
#toggleSourceEverythingModal {
	position: fixed;
	bottom: 0;
	left: 80px;
	z-index: 1000;
	font-size: 10px;
	padding: 10px 15px;
	background-color: #000;
	color: #fff;
	border: none;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	opacity: .75;
	&:hover {
		opacity: 1;
	}
	display: inline-block;
}
#toggleSourceModal {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1000;
	font-size: 10px;
	padding: 10px 15px;
	background-color: #000;
	color: #fff;
	border: none;
	border-top-right-radius: 5px;
	opacity: .75;
	&:hover {
		opacity: 1;
	}
	display: inline-block;
}
.hideMe {
	display: none !important;
}
.noAdminBar {
	margin-top: -32px !important; //move body up
}
@media screen and (max-width: 768px){
	.noAdminBar {
		margin-top: -47px !important;
	}
}       