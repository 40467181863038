ul,
ol {
    &.is-style-inline,
    &.is-style-check-list {
        list-style-type: none !important;
        display: flex;
        flex-wrap: wrap;

        padding-left: 0 !important;

        @include media-breakpoint-down(md) {
            // width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

    }
    &.is-style-inline {
        justify-content: space-between;
        li {
            margin: .5rem;
        }
        &-arrows {
            @extend .is-style-inline;
            a {
                @extend .is-style-chevron-icon;
            }
        }
    }
    &.is-style-check-list {
        justify-content: space-around;
        margin-bottom: 0;

        li {
            flex-basis: auto;
            flex-shrink: 1; 
            flex-grow: 0;
            // color: $theme-purple;
            font-weight: bold;

            display: inline-flex;
            align-items: center;

            margin: .5rem;

            &:before {
                content: '';
                width: 2rem;
                height: 2rem;
                min-width: 2rem;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(/assets/images/checkmark_02.png);
                margin-right: 1rem;
            }

            a {
                color: inherit;
                font-weight: normal;
            }
        }
    }
    &.is-style-leaf-list,
    &.is-style-green-check-list,
    &.is-style-red-x-list {
        list-style-type: none !important;
        display: flex;
        flex-direction: column;

        padding-left: 0;

        li {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start !important;
            margin-bottom: 1.5rem;
            &:before {
                flex-shrink: 0;
            }
        }
    }

    &.is-style-leaf-list {
        li {
            &:before {
                content: '';
                width: 18px;
                height: 28px;
                display: block;
                background-image: escape-svg( url( "data:image/svg+xml,#{$leaf-svg}" ) );
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 1em;
            }
        }
        &.mx-4{
            @include media-breakpoint-down(md) {
                margin-left: 2px !important;
                margin-right: 2px !important;
            }
        }
    }

    &.is-style-green-check-list {
        li {
            @include bootstrap-icon-psuedo( 'check-circle-fill', $green, 'before' );
            &:before {
                height: 1.4em;
                width: 1.2em;
                margin-right: 1em;
                transform: translateY(.2em);
            }
        }
    }
    &.is-style-red-x-list {
        li {
            @include bootstrap-icon-psuedo( 'x-circle-fill', $red, 'before' );
            &:before {
                height: 1.4em;
                width: 1.2em;
                margin-right: 1em;
                transform: translateY(.2em);
            }
        }
    }
}