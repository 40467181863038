// Gutenberg Configuration
// Expose CSS variables
:root {
    @each $key, $color in $gutenberg-colors {
      --#{$key}: #{$color};
    }
}

/// @group Mixins
@mixin block($slug) {
    .wp-block-#{$slug} {
        @content;
    }
}
@mixin media-breakpoint-wp {
    @media screen and (max-width: 781px) {
        @content;
    }
}

/// @group Editor
// Add Editor classes
// Note -- these need to match the slug names of the
// theme color configuration...
@each $key, $color in $gutenberg-colors {
    .has-#{$key}-color{
        color: $color !important;
    }
    .has-#{$key}-background-color{
        background-color: $color !important;
        $_color: choose-contrast-color( $color );
        color: $_color;
        [class*='__inner-container'] {
            color: $_color;
        }
    }
}

@each $key, $font-size in $gutenberg-font-sizes {
    .has-#{$key}-font-size {
        font-size: $font-size !important;
    }
}

// e.g. .container-fluid
.alignfull {
    @extend .container-fluid;
}

// e.g. .container
.alignwide {
    @extend .container;
}

%mobile-container {
    @include media-breakpoint-down(md) {
        &.alignwide,
        &.alignfull {
            max-width: calc(100% - (#{$spacer} * 2) );
        }
    }
}

// unset embedded containers
.alignwide,
.alignfull,
.container,
.container-fluid {
    .alignwide,
    .alignfull {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

/// @group Blocks
@import 
    'anchor',
    'blockquote',
    'button',
    'columns',
    'cover',
    'gallery',
    'glide',
    'gutenslider',
    'list',
    'media-text',
    'video',
    'woocommerce';

// @Devnote: Post Content is wrapped for specificity
// with `#entry-content`
#entry-content {
   
}

.is-style-h1-styling{
    font-size: $h1-font-size!important;
    @extend .h1;
}
.is-style-h2-styling{
    font-size: $h2-font-size!important;
    @extend .h2;
}
.is-style-h3-styling{
    font-size: $h3-font-size!important;
    @extend .h3;
}
.is-style-h4-styling{
    font-size: $h4-font-size!important;
    @extend .h4;
}
.is-style-h5-styling{
    font-size: $h5-font-size!important;
    @extend .h5;
}
.is-style-h6-styling{
    font-size: $h6-font-size!important;
    @extend .h6;
}

.wp-block-image img {
    height: auto
}

// WP 6.0.0 bugfixes
[class^="wp-container-"] {
    gap: 0;
}