/// @group Archive
.posts-wrapper,
.shop-wrapper {
    .posts-sidebar,
    .shop-sidebar {
        @include media-breakpoint-up(lg) {
            .posts-sidebar-menu,
            .shop-sidebar-menu {
                position: sticky;
                top: 140px;
                z-index: 1000;
            }
            .shop-sidebar-menu {
                top: 100px;
            }
        }
    }
}