/// @group Header
.navbar {
    $navbar-bp: lg;

    background-color: $white;
    box-shadow: $box-shadow-lg;

    &-brand {
        img {
            $logo-ratio-width-height: calc(126 / 100);
            transition: all .1s;
            height: 80px;
            width: $logo-ratio-width-height * 80px;
            @include media-breakpoint-down($navbar-bp) {
                height: 60px;
                width: $logo-ratio-width-height * 60px;
            }
        }
        @include media-breakpoint-up($navbar-bp) {
            [data-scroll="0"] &:not(.navbar-brand--woocommerce) {
                img {
                    width: 126px;
                    height: 100px;
                }
            }
        }
        @media (max-width: 767.98px) {
            margin-right: 0;
        }
        &--woocommerce {
            $logo-ratio-width-height: calc(264 / 36);
            img {
                height: 36px;
                width: $logo-ratio-width-height * 36px;
            }
            display: inline-flex;
            align-items: center;
            &:after {
                content: 'STORE';
                letter-spacing: 2px;
                font-size: 1rem;
                font-weight: 500;
                color: $gray-600;
                border-left: 1px solid #797979;

                padding-left: 1rem;
                margin-left: .25rem;
            }

            @include media-breakpoint-down($navbar-bp) {
                img {
                    height: 24px;
                    width: $logo-ratio-width-height * 24px;
                }
                &:after {
                    font-size: .7rem;
                    padding-left: .45rem;
                    margin-left: 0;
                }
            }
        }
    }

    &-toggler {
        &:focus {
            box-shadow: none;
        }
        &[aria-expanded="true"] {
            .navbar-toggler-icon {
                background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
            }
        }
    }

    &-nav {
        font-size: 1rem;
        font-weight: 500;

        &--static {
            flex-direction: row;
            .nav-item + .nav-item {
                margin-left: $spacer * 1.45;
            }
        }
    }

    &-collapse {
        position: absolute;
        right: 0;
        top: 100%;

        // z-index: -1;

        background-color: $gray-100;
        border-top: 1px solid $gray-200;

        width: rem-val( 378 );
        max-width: 100%;

        box-shadow: 0px 3px 8px rgba( $black, .12 );

        border-bottom-left-radius: $border-radius-lg;

        .navbar-nav {
            padding: 2rem 3rem;
            max-height: calc(100vh - 92px);
            height: 100vh;
            overflow: scroll;

            @include media-breakpoint-up( $navbar-bp ) {
                .nav-item:not(.d-lg-none) + .nav-item {
                    margin-top: 0.4rem;
                }
            }

            @media (max-width: 767.98px) {
                max-height: auto;
                height: auto;
            }

            .nav-item:last-child {
                .nav-link {
                    @extend .btn;
                    @extend .btn-primary;
                    padding-left: 3rem;
                    padding-right: 3rem;
                    margin-top: 1rem;
                    &:after {
                        display: none !important;
                    }
                }
            }
        }
    }

    .dropdown {
        &-menu {
            margin-top: .5rem;
            border-color: $primary;
        }
        &-item {
            font-size: rem-val(16);
            font-weight: 500;

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }

    // Button Icons
    .btn {
        &-primary {
            @include bootstrap-icon-psuedo( 'arrow-right', $white );
        }
        &-outline-primary {
            @include bootstrap-icon-psuedo( 'chevron-down', $primary );
            &:hover {
                @include bootstrap-icon-psuedo( 'chevron-down', $white );
            }
            &:after {
                transition: all .2s;
                width: .8em !important;
                height: .8em !important;
                margin-left: .2em !important;
            }
        }

        @include media-breakpoint-down($navbar-bp) {
            font-size: 14px;
            padding-left: calc($spacer / 2) !important;
            padding-right: calc($spacer / 2) !important;
        }
        @media (max-width: 767.98px) {
            font-size: 14px !important;
            padding-left: calc($spacer / 2) !important;
            padding-right: calc($spacer / 2) !important;
            margin-left: 0 !important;
        }
    }

    // Container
    .container-fluid {
        @include media-breakpoint-down($navbar-bp) {
            flex-wrap: nowrap;
        }
    }

    // Woo
    &--woocommerce {
        background-color: $white;
        @include media-breakpoint-down($navbar-bp) {
            .container-fluid {
                flex-direction: column;
                align-items: flex-start;

                .d-flex {
                    width: 100%;
                    margin-top: .25rem;
                    .navbar-link {
                        font-size: 14px;
                        margin-right: auto !important;
                    }
                }
            }
        }
    }
}

// WP Admin Bar
.admin-bar header.sticky-top {
    top: 32px;
}