/// @group Pagination
.pagination {
    display: inline-flex;

    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    padding: .5rem .75rem;

    max-width: 100%;
    flex-wrap: wrap;
}