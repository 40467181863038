/// @group Footer
.page-footer {
    background-color: $gray-100;
    padding-top: 4rem;
    padding-bottom: 2rem;

    @include media-breakpoint-down(lg) {
        .col-lg {
            margin-bottom: 2rem;
        }
    }

    hr {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    font-size: 1rem * calc( 16 / 20 );

    // some stuff is 14 px...
    li.nav-item.nav-item--root > a.nav-link,
    a.social-link,
    strong {
        font-size: 1rem * calc( 14 / 20 ); 
        font-weight: 700;
        text-transform: uppercase;

        color: $body-color;
    }

    .nav {
        flex-direction: column;
        &-item {
            &--root {
                & + .nav-item--root {
                    margin-top: 1rem;
                }
            }
        }
        &-link {
            color: $body-color;
            &:hover {
                text-decoration: underline;
            }

            @include media-breakpoint-down(lg) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &--social {
            margin: 2rem 0;
            .nav-link {
                padding: 0;

                font-weight: 700;
                text-transform: uppercase;

                i {
                    font-size: 1rem;
                    fill: $primary;
                    margin-right: .75rem;
                }
            }
        }
    }

    &-socket {
        margin-top: 2rem;
        .nav {
            flex-direction: row;
            justify-content: center;
            &-link {
                color: $body-color;
                text-transform: uppercase;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    /// @columns
    // Logo / Social
    &-brand-wrapper {
        margin-bottom: 2rem;
    }

    // Nav Links
    &-menu-wrapper {
        margin-bottom: 2rem;
    }

    // Dynamic Text Content
    &-content-wrapper {
        a {
            color: $body-color;
            text-transform: uppercase;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        p:last-child a:last-child {
            display: inline-block;
            margin-top: 2rem;
        }
    }

}