/// @group Headings
h1 {
    line-height: 1.5;
}
h2 {
    font-weight: 500;
}
h5, h6 {
    font-weight: 400;
}
h6 {
    text-transform: uppercase;
}