/// @group Gutenslider
.wp-block-eedee-block-gutenslider {

    &.arrow-position-sides {
        .eedee-gutenslider-nav {
            margin-top: 1rem;
            grid-row: 3 !important;
            grid-column: 1;
            display: inline-block !important;
            position: relative;
            right: auto !important;
            top: auto !important;
            left: auto !important;
            transform: none !important;
            &.eedee-gutenslider-prev {
                transform: translateX(-10px) !important;
            }
            &.eedee-gutenslider-next {
                transform: translateX(40px) !important;
            }
        }
    }

}