/// @group Video
/// @devnote this styling is specific to the hero-video-loop pattern
.wp-block-columns.hero-video-loop {
    margin: 0;
    .wp-block-column {
        align-self: stretch !important;
        margin: 0 !important;

        &:first-child {
            // Content
            display: flex;
            flex-direction: column;
            justify-content: center;

            background-image: url(/assets/images/blue_hero_leaves_half@2x.jpg);
            background-size: cover;
            background-repeat: no-repeat;

            h1 {
                font-size: rem-val(48);
            }
            
            padding: 4rem 0;
            > * {
                @extend .contained-start;
                margin-bottom: $spacer * 2;
                @include media-breakpoint-up(lg) {
                    padding-right: $spacer * 8;
                }

                color: $white !important;
            }
        }
        &:last-child {
            // Video
            padding: 0;
            figure {
                height: 100%;
                video {
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    @media (min-width: 600px) {
        .wp-block-column {
            width: 50% !important;
            flex: 0 0 50% !important;
        }
    }
    @media (max-width: 599px) {
        margin-top: 0;
        .wp-block-column:first-child {
            order: 2;
            padding: 3rem $spacer;
        }
        .wp-block-column:last-child {
            order: 1;
            padding: 0 !important;
        }
    }
}