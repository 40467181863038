/// @group Columns
.wp-block-columns {
  @extend .row;

  @include media-breakpoint-up(md) {
    &.alignwide,
    &.alignfull {
      margin-left: auto;
      margin-right: auto;
    }
  }

  margin-top: 4rem;
  margin-bottom: 4rem;

  @include media-breakpoint-down(md) {
    // ZB: Commented out the overflow hidden so we can see the box shadow of inner panels
    //overflow: hidden;
    // force row display
    width: 100% !important;
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    .wp-block-column {
      flex: 0 0 100%;
      padding-left: $spacer;
      padding-right: $spacer;

      & + .wp-block-column {
        margin-top: 3rem;
      }
    }
    .container &,
    .container-fluid &,
    .container-narrow & {
      .wp-block-column {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.is-style-border-between {

    @include media-breakpoint-up(md) {
      margin-left: -48px !important;
      margin-right: -48px !important;
      width: calc(100% + #{48px * 2}) !important;
      max-width: none !important;

      .wp-block-column {
        padding: 48px;

        > * {
          margin-bottom: 2rem;
        }

        .wp-block-buttons:not([class*="is-style"]) .wp-block-button__link {
          padding-left: 2.5rem !important;
          padding-right: 2.5rem !important;
        }

        &:not(:first-child) {
          // margin-left: 48px;
          border-left: 1px solid $gray-200;
        }
      }
    }
  }

  &.full-width-col-on-tablet {
    @include media-breakpoint-down(md) {
      display: block;
      margin-top: 3rem;
      margin-bottom: 3rem;

      .wp-block-column {
        margin-bottom: 3rem;
      }
    }
  }
}

.wp-block-column {
  @extend .col;

  &:not(:first-child) {
    margin-left: 0;
  }

  // padding-left: $spacer;
  // padding-right: $spacer;

  .is-style-added-padding & {
    @include media-breakpoint-up(lg) {
      padding: 3rem;
    }
  }

  // column content spread out
  > * + * {
    margin-top: 1.5rem;
  }
}