.glide {
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  padding-top: 3rem;
  padding-bottom: 4rem;

  @include media-breakpoint-down(sm) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__track {
    @extend .container;
    overflow: visible !important;
  }

  &__slides {
    overflow: visible !important;
  }

  // &__slide {
  //     @include media-breakpoint-down(md) {
  //         max-width: calc(100vw - (#{$spacer} * 2)) !important;
  //         margin-right: $spacer !important;
  //     }
  // }

  .glide__arrow {
    $size: 4rem;
    font-size: 1.5rem !important;
    padding: 0 !important;
    position: absolute !important;
    top: calc(50% - #{calc($size / 2)});
    margin: 0 3rem;
    width: $size !important;
    height: $size !important;
    background-color: $white !important;
    box-shadow: $box-shadow-sm !important;
    border: none !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    &:before {
      margin: 0 !important;
    }

    &--left {
      left: 0;
      @include bootstrap-icon-psuedo('chevron-left', $body-color, 'before');

      &:before {
        transform: translateX(-2px);
      }
    }

    &--right {
      right: 0;
      @include bootstrap-icon-psuedo('chevron-right', $body-color, 'before');

      &:before {
        transform: translateX(2px);
      }
    }

    span {
      @extend .visually-hidden;
    }

    @include media-breakpoint-down(md) {
      font-size: 1rem !important;
      width: 2rem !important;
      height: 2rem !important;
      margin: 0 1rem !important;
    }
  }

  // testimonials carousel
  &--testimonials {
    padding-top: 0;
    padding-bottom: 0;

    .glide__track {
      overflow: hidden !important;
    }

    .glide__slides {
      align-items: center;
    }

    .container-narrow {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // @products carousel
  &--products {
    .glide__slide {
      align-self: stretch;
      height: auto;
    }

    .card.card--product {
      height: 100%;

      .card-tags {
        display: none;
      }

      .card-img-cap {
        padding: 1.5rem 1rem .5rem 1rem;
        margin: 0;


        picture {
          height: 15vw;
          min-height: 150px;
          max-height: 300px;
          width: 100%;
          position: relative;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .product-title {
        font-weight: 500;
        font-size: rem-val(18);
        line-height: rem-val(20);

        &__link {
          color: inherit;
          text-decoration: none;
        }
      }

      .product-description,
      .product-links {
        display: none !important;
      }
    }
  }
}

.is-style-testimonials {
  @include media-breakpoint-down(md) {
    .glide {

      .glide__track {
        padding-bottom: 40px;
        padding-right: 0;
        padding-left: 0;

        .glide__slides {
          .glide__slide {
            width: 100%;
            margin: 0;
            padding: 0;
            
            .container-narrow{
              // width: 100%;
              // margin: 0;
              padding-right: 0;
              
            }
            blockquote {
              p {
                font-weight: 400;
              }
              p:empty {
                display: none;
              }
            }
          }
        }
      }

      .glide__arrows {

        .glide__arrow {
          top: auto !important;
          bottom: 20px;
          padding: 0;
          height: 3rem;
          width: 4rem;
        }
      }
    }
  }
}