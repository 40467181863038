.drop-cap {
    &:first-child::first-letter {
      color: #00afd0;
      padding-left: 0;
      padding-right: 10px;
      float: left;
      position: relative;
      top: 0;
      margin-top: 1rem;
      margin-bottom: 0;
      margin-left: 0px;
      margin-right: 2px;
      font-size: 80px;
      font-weight: bold;
      
      @include media-breakpoint-down(md){
        font-size: 40px;
      }
    }
  } 
  
  .bg-image,
  .bg-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .bg-white-gradient {
    background: linear-gradient(to right, transparent 0%, white 25%, white 75%, transparent 100%);
  }
  
  // Colors : name, color, default color if "color" background
  $custom-colors: (
    // "primary" #00a0bd white,
    // "dark-primary" #008299 white,
    // "blue" #00a0bd white,
    "dark-blue" #008299 white,
    // "muted-blue" #1a7f95 white,
    // "dark-gray" #666 white,
    // "almost-black" #1d2428 white,
    // "light-gray" #f0f1f1 #333f48,
    // "med-gray" #eaebec #333f48,
    "black" #000 #fff,
    "white" #ffffff #1C2E36,
    'navy' #074D70 #1C2E36,
    'navy-dark' #00447c #1C2E36,
    'blue' #1A7CAD #1C2E36,
    'cyan' #3AA9E1 #1C2E36,
    'green' #80C41C #1C2E36,
    'yellow' #F6BB2E #1C2E36,
    'orange' #F8A11D #1C2E36,
    'red' #FE503F #1C2E36,
    'purple' #50014B #1C2E36,
    'light-gray' #F8F8F9 #1C2E36,
    'medium-gray' #868E96 #1C2E36,
    'dark-gray' #1C2E36 #1C2E36,
  );
  
  @each $name, $value, $default-color in $custom-colors {
    .bg-#{$name} {
      background-color: $value !important;
  
      * {
        color: $default-color;
      }
      input{
        color: #333f48;
      }
    }
    .color-#{$name} {
      color: $value !important;
      fill: $value !important;
      *{
        color: $value !important;
        fill: $value !important;
      }
    }
    .btn-#{$name} {
      @include flat-button(
        $bg-color: $value,
        $hover-bg-color: darken($value, 14),
        $border-color: darken($value, 4),
        $hover-border-color: darken($value, 14),
        $double-border: false,
        $double-border-color: lighten($value, 14),
        $round: 0px,
        $text-color: $default-color,
        $text-hover-color: $default-color,
        $padding: 6px 12px,
        $font-size: 24px
      );
      @extend .btn-md;
    }
    .btn-#{$name}-outline {
      @include flat-button(
        $bg-color: transparent,
        $hover-bg-color: $value,
        $border-color: $value,
        $hover-border-color: $value,
        $double-border: false,
        $double-border-color: lighten($default-color, 14),
        $round: 0px,
        $text-color: $value,
        $text-hover-color: $default-color,
        $padding: 6px 12px,
        $font-size: 24px
      );
      @extend .btn-md;
    }
    .btn-#{$name}-text-outline {
      @include flat-button(
        $bg-color: transparent,
        $hover-bg-color: transparent,
        $border-color: transparent,
        $hover-border-color: $value,
        $double-border: false,
        $double-border-color: lighten($default-color, 14),
        $round: 0px,
        $text-color: $value,
        $text-hover-color: darken($value,14),
        $padding: 6px 12px,
        $font-size: 24px
      );
      @extend .btn-md;
    }
    .btn-#{$name}-text {
      color: $value;
      text-decoration: none;
  
      &:hover,
      &:focus{
        color: darken($value,12);
      }
    }
    .border-#{$name} {
      border: 1px solid $value;
    }
    .input-group > .input-group-append > .btn{
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
      // position: relative;
      // z-index: 2;
      padding: 6px 12px;
      margin: 0;
      font-size: 16px;
      border-bottom-right-radius: rem(4);
      border-top-right-radius: rem(4);
      
    }
    .toggle.toggle-#{$name}{
      background: $value;
      border-color: $value;
      i {
        color: $default-color;
      }
      &:hover,
      &:focus {
        background: transparent;
        text-decoration: none;
        i {
          color: $value;
        }
      }
      &.toggle-circle{
        border-color: $value;
      }
    }
    .toggle.toggle-#{$name}-outline{
      background: transparent;
      border-color: $value;
      i {
        color: $value;
      }
      &:hover,
      &:focus {
        background: $value;
        text-decoration: none;
        i {
          color: $default-color;
        }
      }
      &.toggle-circle{
        border-color: $value;
      }
    }
    .badge.badge-#{$name}{
      background: $value;
      display: flex;
      align-items: center;
      font-weight: normal;
      display: inline-block;
      border-color: $value;
      @extend .py-04;
      @extend .px-16;
      @extend .fz-20;
  
      i {
        color: $default-color;
      }
    }
    a.badge.badge-#{$name}{
      color: $default-color;
      text-decoration: none;
      @include transition();
      
      &:hover,
      &:focus{
        color: $default-color;
        background-color: darken($value, 10);
      }
    }
    button.badge.badge-#{$name}{
      @include transition();
      &:hover,
      &:focus{
        background: darken($value,12);
      }
    }
  }
  
  @function custom-color($key) {
    @if map-has-key($custom-colors, $key) {
      @return map-get($custom-colors, $key);
    }
  
    @warn "Unknown `#{$key}` in $colors.";
    @return null;
  }
  
  // Font Sizes : name, desktop line-height, xxl size, xl size, lg size, md size, sm size, xs size
  $custom-font-sizes: (
    "14" 1.5 14 14 14 14 14 14,
    "16" 1.5 16 16 16 16 16 16,
    "18" 1.5 20 20 20 16 16 16,
    "20" 1.5 20 20 20 16 16 16,
    "24" 1.5 24 24 24 22 18 16,
    "28" 1.5 28 28 28 24 18 18,
    "32" 1.4 32 32 32 24 18 18,
    "36" 1.3 36 36 36 24 18 18,
    "40" 1.3 40 40 40 28 24 18,
    "50" 1.3 50 50 50 32 24 24,
    "80" 1 80 80 60 40 32 32,
    "128" 1 128 128 60 40 32 32
  );
  
  @each $name, $line-height, $xxl-size, $xl-size, $lg-size, $md-size, $sm-size, $xs-size in $custom-font-sizes {
    .fz-#{$name} {
      font-size: rem($xs-size);
      line-height: normal;
      @include media-breakpoint-only(sm) {
        font-size: rem($sm-size);
        line-height: normal;
      }
      @include media-breakpoint-only(md) {
        font-size: rem($md-size);
        line-height: normal;
      }
      @include media-breakpoint-only(lg) {
        font-size: rem($lg-size);
        line-height: normal;
      }
      @include media-breakpoint-only(xl) {
        font-size: rem($xl-size);
        line-height: $line-height;
      }
      @include media-breakpoint-only(xxl) {
        font-size: rem($xxl-size);
        line-height: $line-height;
      }
    }
    body .section, body .sxs{ 
      p.fz-#{$name} {
          @extend .fz-#{$name};
        }
    }
  }
  
  
  // Custom Spacing Sizes : name, xxl spacing, xl spacing, lg spacing, md spacing, sm spacing, xs spacing
  // This will give extra margin and padding classes to use that change in value for all screen sizes.
  // for each size added to the array, p(t,r,b,l), m(t,r,b,l), py, my, px and mx are all created
  $custom-spacing-sizes: (
    "04" 4 4 4 4 2 2,
    "8" 8 8 8 4 4 4,
    "16" 16 16 16 8 8 8,
    "20" 20 20 20 16 16 16,
    "24" 24 24 24 22 18 16,
    "28" 28 28 28 24 20 20,
    "32" 32 32 32 24 20 20,
    "36" 36 36 36 24 20 20,
    "48" 48 48 48 28 18 18,
    "64" 64 64 64 40 32 32,
    "80" 80 80 80 40 32 32,
    "96" 96 96 96 40 32 32,
    "128" 128 128 128 60 40 40,
    "210" 210 210 210 60 40 40,
    "250" 250 250 250 60 40 40,
    "300" 300 300 300 60 40 40
  );
  
  $spacing-types: ("m" "margin", "p" "padding");
  $sides: ("" "", "t" "-top", "b" "-bottom", "l" "-left", "r" "-right");
  @each $name, $xxl-spacing, $xl-spacing, $lg-spacing, $md-spacing, $sm-spacing, $xs-spacing in $custom-spacing-sizes {
    @each $spacing-type-abbreviation, $spacing-type in $spacing-types {
      @each $side-abbreviation, $side in $sides {
        .#{$spacing-type-abbreviation}#{$side-abbreviation}-#{$name} {
          #{$spacing-type}#{$side}: rem($xs-spacing);
          @include media-breakpoint-only(sm) {
            #{$spacing-type}#{$side}: rem($sm-spacing);
          }
          @include media-breakpoint-only(md) {
            #{$spacing-type}#{$side}: rem($md-spacing);
          }
          @include media-breakpoint-only(lg) {
            #{$spacing-type}#{$side}: rem($lg-spacing);
          }
          @include media-breakpoint-only(xl) {
            #{$spacing-type}#{$side}: rem($xl-spacing);
          }
          @include media-breakpoint-only(xxl) {
            #{$spacing-type}#{$side}: rem($xxl-spacing);
          }
        }
      }
      .#{$spacing-type-abbreviation}y-#{$name} {
        #{$spacing-type}-top: rem($xs-spacing);
        #{$spacing-type}-bottom: rem($xs-spacing);
        @include media-breakpoint-only(sm) {
          #{$spacing-type}-top: rem($sm-spacing);
          #{$spacing-type}-bottom: rem($sm-spacing);
        }
        @include media-breakpoint-only(md) {
          #{$spacing-type}-top: rem($md-spacing);
          #{$spacing-type}-bottom: rem($md-spacing);
        }
        @include media-breakpoint-only(lg) {
          #{$spacing-type}-top: rem($lg-spacing);
          #{$spacing-type}-bottom: rem($lg-spacing);
        }
        @include media-breakpoint-only(xl) {
          #{$spacing-type}-top: rem($xl-spacing);
          #{$spacing-type}-bottom: rem($xl-spacing);
        }
        @include media-breakpoint-only(xxl) {
          #{$spacing-type}-top: rem($xxl-spacing);
          #{$spacing-type}-bottom: rem($xxl-spacing);
        }
      }
      .#{$spacing-type-abbreviation}x-#{$name} {
        #{$spacing-type}-left: rem($xs-spacing);
        #{$spacing-type}-right: rem($xs-spacing);
        @include media-breakpoint-only(sm) {
          #{$spacing-type}-left: rem($sm-spacing);
          #{$spacing-type}-right: rem($sm-spacing);
        }
        @include media-breakpoint-only(md) {
          #{$spacing-type}-left: rem($md-spacing);
          #{$spacing-type}-right: rem($md-spacing);
        }
        @include media-breakpoint-only(lg) {
          #{$spacing-type}-left: rem($lg-spacing);
          #{$spacing-type}-right: rem($lg-spacing);
        }
        @include media-breakpoint-only(xl) {
          #{$spacing-type}-left: rem($xl-spacing);
          #{$spacing-type}-right: rem($xl-spacing);
        }
        @include media-breakpoint-only(xxl) {
          #{$spacing-type}-left: rem($xxl-spacing);
          #{$spacing-type}-right: rem($xxl-spacing);
        }
      }
    }
  }
  
  .mt--64{
    margin-top: -64px;
  }
  .px-mobile-0{
    @include media-breakpoint-down(md){
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  
  // Button Padding : name, font-size, xxl/xl/lg py, xxl/xl/lg px, md py, md px, sm py, sm px
  // font size has to exist in the custom-font-sizes above
  $custom-button-padding: ("lg" 24 26 96 20 48 16 28, "md" 20 14 40 12 32 12 32, "sm" 18 8 16 8 16 8 16);
  
  @each $name, $font-size, $py-xl, $px-xl, $py-md, $px-md, $py-sm, $px-sm in $custom-button-padding {
    .btn.btn-#{$name} {
      @extend .fz-#{$font-size};
      margin: rem(6);
      @include media-breakpoint-down(xxl) {
        padding: rem($py-xl) rem($px-xl);
      }
      @include media-breakpoint-down(md) {
        padding: rem($py-md) rem($px-md);
      }
      @include media-breakpoint-down(sm) {
        padding: rem($py-sm) rem($px-sm);
      }
    }
  }
  
  .font-bold{
    font-weight: 900;
  }
  
  .font-leadin {
    @extend .fz-24;
    color: #333f48;
    letter-spacing: 0;
    line-height: 41px;
  }
  
  ul {
    &.ul-chevron {
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
  
      li{
        margin-top: rem(8);
        margin-bottom: rem(8);
        position: relative;
        padding-left: 1.7em;
  
        &:before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-family: "Font Awesome 5 Pro";
          font-weight: 400;
          content: "\f054";
          @extend .color-blue;
          position: absolute;
          left: 0;
        }
  
        p{
          display: inline-block;
        }
      }
  
      &.ul-blue-chevron{
  
        li{
  
          &:before {
            @extend .color-blue;
          }
        }
      }
      &.ul-dark-blue-chevron{
  
        li{
  
          &:before {
            @extend .color-dark-blue;
          }
        }
      }
    }
  }
  
  // .blue-chevron {
  //   font-weight: normal;
  //   &:before {
  //     content: "";
  //     display: inline-block;
  //     height: .7em;
  //     width: .7em;
  //     background-image: url("/assets/images/right-chevron.svg");
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     padding-left: .7em;
  //   }
  // }
  .triangle {
    display: block;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 0.25em;
  }
  
  .shadow {
    box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.2) !important
  }
  
  .toggle{
    width: rem(48);
    height: rem(48);
    border-width: 2px;
    border-style: solid;
    text-decoration: none;
    @include transition();
  
    &.toggle-circle {
      border-radius: 50%;
    
      i {
        font-size: rem(20);
      }
    }
  }
  
  .transition{
      @include transition(300ms);
  }
  
  .animate-slow{
    animation-duration: 10s;
    animation-delay: 5s; 
  }
  
  /* 5 Columns */
  
  .col-15,
  .col-sm-15,
  .col-md-15,
  .col-lg-15 {
    // position: relative;
    // min-height: 1px;
    // padding-right: 10px;
    // padding-left: 10px;
    // flex: 0 0 20%;
    // max-width: 20%;
  }
  
  .col-15 {
    flex: 0 0 20%;
    max-width: 20%;
  padding-left: rem(8);
  padding-right: rem(8);
}
@media (min-width: 768px) {
  .col-sm-15 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: rem(8);
    padding-right: rem(8);
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: rem(8);
    padding-right: rem(8);
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: rem(8);
    padding-right: rem(8);
    }
  
    .w-lg-100{
      width: 100% !important;
    }
  }
  