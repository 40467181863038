.wp-block-gallery {
    padding: 3rem 0;
    margin: 4rem 0;

    background-color: $gray-100;
    max-width: 100% !important;

    &.alignwide {
        .blocks-gallery-grid {
            @extend .container;
            padding: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 4rem 0;
    }
    @include media-breakpoint-up(md) {
        .blocks-gallery-grid {
            flex-direction: row;
            .blocks-gallery-item {
                padding: .75rem;
                align-self: center;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .blocks-gallery-grid {
            flex-wrap: wrap;
            align-items: center;
            // padding-left: $spacer !important;
            // padding-right: $spacer !important;
            .blocks-gallery-item {
                flex: 0 0 50%;
                margin: 0 !important;
                padding: $spacer !important;
            }
        }
    }

    // wp6.0.0 updates
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    .wp-block-image {
        margin: 0 !important;
        padding: 1rem;
        @media (min-width: 600px) {
            padding: 0 1rem;
        }
    }
}