*{
    scroll-margin-top: 130px;
}

@media (max-width: 768px) {
    .wp-block-group.has-background{
        padding: 15px 15px 0;
    }
}

.wp-block-columns{
    .wp-block-column{
        &.ms-5{
            @media (max-width: 768px) {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}

body{
    @media (max-width: 768px) {
        font-size: 16px !important;

        h1{
            font-size: 34px;
        }
        h2{
            font-size: 28px;
        }
        h3{
            font-size: 24px;
        }
        h4{
            font-size: 22px;
        }
        h5{
            font-size: 20px;
        }
        h6{
            font-size: 18px;
        }
    }
}

.gform_anchor {
    position: relative;
    top: -20px;
}


.kill-all-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding: 10px !important;
    }
}

.kill-right-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding-right: 10px !important
    }
}

.kill-left-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding-left: 10px !important
    }
}

.kill-bottom-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding-bottom: 10px !important
    }
}

.kill-top-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding-top: 10px !important
    }
}

.kill-y-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

.kill-x-padding-on-mobile {
    @include media-breakpoint-down(md) {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
}