.container {
    &-fluid {
        @include media-breakpoint-up(lg) {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
        }

        &--woocommerce {
            @include media-breakpoint-up(lg) {
                padding-left: $spacer * 3;
                padding-right: $spacer * 3;
            }
        }
    }

    &-narrow {
        @extend .container;
        @include media-breakpoint-up(sm) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @include media-breakpoint-up(lg) {
            max-width: 940px;
            padding-left: $spacer;
            padding-right: $spacer;
        }
        
        .container,
        .alignwide,
        .alignfull {
            width: 100%;
            max-width: 100%;

            @include media-breakpoint-up(md) {
                &.wp-block-columns {
                    width: calc( 100% + 2rem );
                    max-width: none !important;
                    margin-left: -1rem;
                    margin-right: -1rem;
                }
            }
        }

        margin-top: 4rem;
        margin-bottom: 4rem;

        @include media-breakpoint-down(sm) {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
        }
    }
}

// @Support for 1/2 containers
.container-start,
.container-end {
    padding-left: $spacer;
    padding-right: $spacer;
}
.contained-start {
    @each $bp, $width in $container-max-widths {
        @include media-breakpoint-up($bp) {
            max-width: calc($width / 2);
            margin-left: auto;
        }
    }
}
.contained-end {
    @each $bp, $width in $container-max-widths {
        @include media-breakpoint-up($bp) {
            max-width: calc($width / 2);
            margin-right: auto;
        }
    }
}