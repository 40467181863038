/// @group Icon
$icons: ( 
    'arrow-right', 
    'chevron-down', 
    'chevron-right',
    'facebook',
    'twitter',
    'pinterest',
    'instagram'
);
@each $icon in $icons {
    .has-icon-#{$icon} {
        @include bootstrap-icon-psuedo( $icon, currentColor );
    }
}